
// This module is used to track form submissions by sending information
// to the dataLayer which is picked up by Google Tag Manager, which
// proceeds to forward the event onwards to GA4.
export default function init() {
    const forms = [...document.querySelectorAll('.fui-form')];
    forms.forEach((form) => {
      form.addEventListener('onAfterFormieSubmit', (e) => {
        e.preventDefault();
        const response = e.detail;
        if (response.success) {
          const { formHandle } = JSON.parse(form.dataset.fuiForm);
          // Set tracking object
          const tracking = {
            event: 'form_submission',
            form_handle: formHandle,
          };
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(tracking);
        }
      });
    });
  }