/** @module lazyLoad */

// Polyfill required for IE11 Support
import 'intersection-observer';

/**
 * Initiate lazyLoad module
 */
export default function init() {
  const lazyElements = [...document.querySelectorAll('.js-lazy')];
  // If at least one lazy element exists
  if (lazyElements.length > 0) {
    lazyElements.forEach((element) => {
      element.classList.add('u-lazy');
    });
    // Dynmically import the lazy loading library "lozad" & initiate
    import('lozad')
      .then(({ default: lozad }) => {
        const observer = lozad('.js-lazy', {
          loaded: (el) => {
            el.classList.add('u-lazy--loaded');
            if (el.dataset.sizes) {
              el.setAttribute('sizes', el.dataset.sizes);
              el.removeAttribute('data-sizes');
            }
          },
        });
        observer.observe();
      })
      .catch((error) => console.error(error));
  }
}
